"use client";
import Link from "next/link";
import Image from "next/image";
import { uniqueId } from "lodash-es";

import { applicationSettings } from "@/constants";
import { Button } from "@/ui/shadcn";
import { ResponsiveContainer } from "@/ui/components";
const { LEGACY_WEBSITE_URL } = applicationSettings;

const dataFindWhatYouNeed = [
  {
    title: "Yoga",
    alt: "Modalities Yoga",
    link: `${LEGACY_WEBSITE_URL}/yoga-online`,
    src: "/v2/images/landing/find-what-you-need/yoga.webp"
  },
  {
    title: "Pilates",
    alt: "Modalities Pilates",
    link: `${LEGACY_WEBSITE_URL}/styles/mat-pilates`,
    src: "/v2/images/landing/find-what-you-need/pilates.webp"
  },
  {
    title: "Meditation",
    alt: "Modalities Meditation",
    link: `${LEGACY_WEBSITE_URL}/meditation-online`,
    src: "/v2/images/landing/find-what-you-need/meditation.webp"
  },
  {
    title: "Fitness",
    alt: "Modalities Fitness",
    link: `${LEGACY_WEBSITE_URL}/styles`,
    src: "/v2/images/landing/find-what-you-need/fitness.webp"
  }
];

export const FindWhatYouNeed = () => {
  return (
    <section id="schedule" className="bg-white py-16 dark:bg-surface-primary max-md:px-4 md:py-32">
      <ResponsiveContainer>
        <div className="mb-16 gap-8 md:grid md:grid-cols-2">
          <div>
            <h2 className="mb-12 max-w-[550px] text-[32px] font-medium !leading-[120%] md:mb-16 md:text-[40px] xl:text-5xl">
              Find what you need, day by day
            </h2>
            <Link
              href={`${LEGACY_WEBSITE_URL}/live`}
              target="_blank"
              className="hidden md:block"
              onContextMenu={(e) => e.preventDefault()}
            >
              <Button className="!pr-8">Explore our livestream schedule</Button>
            </Link>
          </div>
          <div className="text-base !leading-[144%] md:text-xl xl:text-2xl">
            From morning energy boosts to beginner and intermediate yoga, meditation, and even dynamic yoga workouts,
            Glo&apos;s live classes have everything you need. Our schedule is updated regularly to offer fresh, timely
            content that syncs with the seasons, holidays, mood phases, and specific goals.
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 max-md:mb-16 md:grid-cols-2 md:gap-8">
          {dataFindWhatYouNeed.map((item) => (
            <div key={uniqueId(item.title)} className="group relative overflow-hidden rounded-[8px]">
              <Link href={item.link} target="_blank">
                <div className="title-card absolute left-0 top-0 z-10 p-5 text-base font-medium !leading-none text-white md:p-6 md:text-xl xl:p-6">
                  {item.title}
                </div>
                <Image
                  src={item.src}
                  alt={item.alt}
                  width={720}
                  height={324}
                  loading="lazy"
                  className="w-full rounded-[8px] md:transition-transform md:duration-500 md:ease-in-out md:group-hover:scale-110"
                />
              </Link>
            </div>
          ))}
        </div>
        <Link
          href={`${LEGACY_WEBSITE_URL}/live`}
          target="_blank"
          className="inline-block md:hidden"
          onContextMenu={(e) => e.preventDefault()}
        >
          <Button className="!pr-8">Explore our livestream</Button>
        </Link>
      </ResponsiveContainer>
    </section>
  );
};
