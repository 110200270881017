import { useCallback } from "react";

import { GLO_GUIDES_ANALYTICS_EVENTS } from "@/features/Analytics/types/gloGuidesAnalytics.constants";

import { useTrackEvent } from "./useTrackEvent";
import { useAnalytics } from "./useAnalytics";

const useAnalyticsGloGuides = () => {
  const trackEvent = useTrackEvent();
  const { getCommonProperties } = useAnalytics();

  // Tracks when a user clicks to start a trial
  // Includes user ID, page name, and optional campaign ID
  const trackClickStartTrial = useCallback(
    (userId: string, pageName: string, campaignId?: string) => {
      const commonProperties = getCommonProperties();
      trackEvent(GLO_GUIDES_ANALYTICS_EVENTS.TRACK.START_TRIAL, {
        ...commonProperties,
        userId,
        pageName,
        referrer: document?.referrer || "",
        campaignId: campaignId ?? ""
      });
    },
    [trackEvent, getCommonProperties]
  );

  // Tracks when a user visits the paywall
  // Includes user ID and device information
  const trackVisitPaywall = useCallback(
    (userId: string) => {
      const commonProperties = getCommonProperties();
      trackEvent(GLO_GUIDES_ANALYTICS_EVENTS.VISIT.PAYWALL, {
        ...commonProperties,
        userId,
        pageName: "Your Plan Paywall View",
        referrer: document.referrer || "",
        browserName: navigator.userAgent,
        screenResolution: `${window.screen.width}x${window.screen.height}`
      });
    },
    [trackEvent, getCommonProperties]
  );

  // Tracks successful trial subscriptions
  // Includes user ID, plan details, and payment information
  const trackTrialSubscriptionSuccessful = useCallback(
    (userId: string, planId: string, paymentMethod: string, trialPeriod: number, couponCode?: string) => {
      const commonProperties = getCommonProperties();

      trackEvent(GLO_GUIDES_ANALYTICS_EVENTS.TRACK.TRIAL_SUBSCRIPTION_SUCCESSFUL, {
        ...commonProperties,
        userId,
        planId,
        pageName: "Glo Guides Checkout View",
        paymentMethod,
        trialPeriod,
        couponCode: couponCode ?? ""
      });
    },
    [trackEvent, getCommonProperties]
  );

  // Tracks submission of intake form
  // Includes user ID, form version, and response summary
  const trackSubmitIntakeForm = useCallback(
    (userId: string, intakeFormVersion: string, responsesSummary: object) => {
      const commonProperties = getCommonProperties();
      trackEvent(GLO_GUIDES_ANALYTICS_EVENTS.TRACK.SUBMIT_INTAKE_FORM, {
        ...commonProperties,
        userId,
        intakeFormVersion,
        responsesSummary
      });
    },
    [trackEvent, getCommonProperties]
  );

  // Tracks subscription cancellations
  // Includes user ID, plan details, and cancellation reason
  const trackGloGuidesCancel = useCallback(
    (userId: string, planId: string, nextBillingDate: string, cancellationReason: string) => {
      const commonProperties = getCommonProperties();
      trackEvent(GLO_GUIDES_ANALYTICS_EVENTS.TRACK.CANCEL, {
        ...commonProperties,
        userId,
        planId,
        nextBillingDate,
        cancellationReason
      });
    },
    [trackEvent, getCommonProperties]
  );

  // Tracks when Glo Guides is selected during core intro
  // Includes user ID and intro step information
  const trackCoreIntroAddGloGuidesSelected = useCallback(
    (userId: string, introStep: string) => {
      const commonProperties = getCommonProperties();
      trackEvent(GLO_GUIDES_ANALYTICS_EVENTS.TRACK.CORE_INTRO_ADD_GLO_GUIDES_SELECTED, {
        ...commonProperties,
        userId,
        introStep,
        referrer: document.referrer || "",
        deviceType: commonProperties.platform
      });
    },
    [trackEvent, getCommonProperties]
  );

  // Tracks successful paid conversions
  // Includes user ID, plan details, and payment information
  const trackPaidConversionSuccessful = useCallback(
    (userId: string, planId: string, paymentMethod: string, amount: number, currency: string, couponCode?: string) => {
      const commonProperties = getCommonProperties();
      trackEvent(GLO_GUIDES_ANALYTICS_EVENTS.TRACK.PAID_CONVERSION_SUCCESSFUL, {
        ...commonProperties,
        userId,
        planId,
        pageName: "Glo Guides Checkout View",
        paymentMethod,
        amount,
        currency,
        couponCode: couponCode ?? "",
        referrer: document?.referrer ?? ""
      });
    },
    [trackEvent, getCommonProperties]
  );

  // Tracks when a user starts the intake form
  // Includes user ID and page name
  const trackIntakeFormStarted = useCallback(
    (userId: string) => {
      const commonProperties = getCommonProperties();
      trackEvent(GLO_GUIDES_ANALYTICS_EVENTS.TRACK.INTAKE_FORM_STARTED, {
        ...commonProperties,
        userId,
        pageName: "Intake Form View",
        referrer: document.referrer || "",
        deviceType: commonProperties.platform
      });
    },
    [trackEvent, getCommonProperties]
  );

  // Tracks when a user fails to make a payment
  // Includes user ID, plan details, and payment information
  const trackPaymentFailed = useCallback(
    (userId: string, planId: string, paymentMethod: string, amount: number, currency: string) => {
      const commonProperties = getCommonProperties();
      trackEvent(GLO_GUIDES_ANALYTICS_EVENTS.TRACK.PAYMENT_FAILED, {
        ...commonProperties,
        userId,
        planId,
        paymentMethod,
        amount,
        currency
      });
    },
    [trackEvent, getCommonProperties]
  );

  const trackNewHomePage = useCallback(() => {
    trackEvent(GLO_GUIDES_ANALYTICS_EVENTS.VISIT.NEW_HOME_PAGE, {
      ...getCommonProperties(),
      pageName: "New home page"
    });
  }, [trackEvent, getCommonProperties]);

  return {
    trackClickStartTrial,
    trackVisitPaywall,
    trackTrialSubscriptionSuccessful,
    trackSubmitIntakeForm,
    trackGloGuidesCancel,
    trackCoreIntroAddGloGuidesSelected,
    trackPaidConversionSuccessful,
    trackIntakeFormStarted,
    trackPaymentFailed,
    trackNewHomePage
  };
};

export default useAnalyticsGloGuides;
