"use client"
import { uniqueId } from "lodash-es";
import Image from "next/image";
import Link from "next/link";

import { ResponsiveContainer } from "@/ui/components";
import { Button } from "@/ui/shadcn";
import { applicationSettings } from "@/constants";
const { LEGACY_WEBSITE_URL } = applicationSettings;

const dataPratices = [
  {
    title: "Vinyasa Flow",
    src: "/v2/images/landing/practices/vinyasa.webp",
    href: `${LEGACY_WEBSITE_URL}/styles/vinyasa-flow`
  },
  { title: "Hatha", src: "/v2/images/landing/practices/hatha.webp", href: `${LEGACY_WEBSITE_URL}/styles/hatha` },
  { title: "Yin", src: "/v2/images/landing/practices/yin.webp", href: `${LEGACY_WEBSITE_URL}/styles/yin` },
  {
    title: "Restorative",
    src: "/v2/images/landing/practices/restorative.webp",
    href: `${LEGACY_WEBSITE_URL}/styles/restorative`
  },
  {
    title: "Kudalini",
    src: "/v2/images/landing/practices/kundalini.webp",
    href: `${LEGACY_WEBSITE_URL}/styles/kundalini`
  },
  {
    title: "Yoga Conditioning",
    src: "/v2/images/landing/practices/conditioning.webp",
    href: `${LEGACY_WEBSITE_URL}/styles/yoga-conditioning`
  },
  {
    title: "Power Yoga",
    src: "/v2/images/landing/practices/power.webp",
    href: `${LEGACY_WEBSITE_URL}/styles/power-yoga`
  },
  {
    title: "Prenatal",
    src: "/v2/images/landing/practices/prenatal.webp",
    href: `${LEGACY_WEBSITE_URL}/styles/prenatal`
  }
];
export const Practices = () => {
  return (
    <section id="styles">
      <ResponsiveContainer>
        <div className="relative py-16 max-md:px-4 md:py-[120px]">
          <h2 className="mb-12 text-[32px] font-medium leading-tight md:mb-16 md:text-[40px] xl:text-5xl">
            Create a practice that has purpose
          </h2>
          <div className="mb-16 text-base md:text-xl xl:text-2xl">
            Whether you’re just beginning or you have lots of experience, Glo caters to everyone from curious to
            committed practitioners. Our diverse styles guide you to build a routine that nourishes your complete
            wellness – body, mind, and spirit.
          </div>
          <div className="mb-16 grid grid-cols-2 gap-4 md:grid-cols-3 md:gap-8 lg:grid-cols-4">
            {dataPratices.map((practice) => (
              <div key={uniqueId(practice.title)} className="relative overflow-hidden">
                <Link
                  href={practice.href}
                  target="_blank"
                  className="group block overflow-hidden rounded-[8px] after:absolute after:left-0 after:top-0 after:size-full after:rounded-[8px] after:bg-black after:opacity-[0.24] after:content-['']"
                >
                  <div className="title-card absolute left-0 top-0 z-10 p-4 text-white md:p-6 xl:p-8">
                    <div className="mb-1 text-[8px] font-medium uppercase leading-none md:text-[10px] xl:text-xs">
                      Yoga
                    </div>
                    <div className="max-w-[66px] text-xs leading-tight md:max-w-[100px] md:text-sm xl:text-base">
                      {practice.title}
                    </div>
                  </div>
                  <Image
                    className="w-full rounded-[8px] object-cover md:transition-transform md:duration-500 md:ease-in-out md:group-hover:scale-110"
                    src={practice.src}
                    alt={practice.title}
                    width={350}
                    height={350}
                    loading="lazy"
                  />
                </Link>
              </div>
            ))}
          </div>
          <div className="relative">
            <Link href={`${LEGACY_WEBSITE_URL}/styles`} target="_blank" onContextMenu={(e) => e.preventDefault()}>
              <Button variant="primary" className="!pr-8">
                Explore our 25+ styles
              </Button>
            </Link>
          </div>
        </div>
      </ResponsiveContainer>
    </section>
  );
};
